import React, { Component } from 'react'
import LeftFilter from './LeftFilter'
import { ReactComponent as Filter} from '../../assets/images/funnel.svg';
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap';

export default class TopFilter extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true,
			SearchPropertyModel:false,
		}
	}

	componentDidMount(){
          
	}

	SearchPropertyModel() {
        this.setState({
            SearchPropertyModel: !this.state.SearchPropertyModel
        })
    }

	ModalClose() {
        this.setState({
            SearchPropertyModel: false
        })
    }

	

	render() {
		return ( 
	    <div class="property-sorting  d-none">
	    	<div class="filter_area">
	    		<Filter className="img-svg" alt="home" onClick={() => this.props.toggleView()} /> <span>Filter</span>
			</div>
		
			<div class="d-flex justify-content-between">
				<div>
					{/* <h2>Found {this.props.propertyListLength} Of {this.props.totalproperty} Results</h2> */}
				</div>
				<div>
					<div class="dropdown">
					<a class="btn btn-default dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> Sort By </a>

					<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
						<li><a class="dropdown-item" href="#">A-Z</a></li>
						<li><a class="dropdown-item" href="#">Z-A</a></li>
					</ul>
					</div>
				</div>
			</div>
			{/* <Modal size="md" isOpen={this.state.SearchPropertyModel} toggle={() => this.ModalClose()} autoFocus={false}>
                    <ModalHeader toggle={() => this.ModalClose()}>Search properties</ModalHeader>
                    <ModalBody>
                        {this.state.scsMsg ?
                            <div className="text-success">
                                {this.state.scsMsg}
                            </div>
                            : ""}
                        <form>
				    <div class="mb-3">
				      <label  class="form-label">Where</label>
				      <input type="text"
					   class="form-control" 
					   placeholder="Enter Location" />
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Purpose</label>
				      <select class="form-select" aria-label="Default select example">
				        <option value="">Select</option>
				        <option value="1">For Rent</option>
				        <option value="1">For Sale</option>
				        <option value="1">Short let</option>
				      </select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Property Type</label>
				      <select class="form-select" aria-label="Default select example">
				        <option value="">Select Property Type</option>
				        <option value="0">Co-working space</option>
				        <option value="0">Commercial Property</option>
				        <option value="0">Flat / Apartment</option>
				        <option value="0">House</option>
				        <option value="0">Land</option>
				      </select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Sub Property Type</label>
				      <select class="form-select" aria-label="Default select example">
				        <option value="">Select Property</option>
				        <option value="0">Conference Room</option>
				        <option value="0">Desk</option>
				        <option value="0">Meeting Room</option>
				        <option value="0">Private Office</option>
				        <option value="0">Workstation</option>
				      </select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Price</label>
				      <select class="form-select" aria-label="Default select example">
				        <option value="">Select Currency</option>
				        <option value="0">Dollar</option>
				        <option value="0">Naira</option>
				        <option value="0">Pounds</option>
				      </select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Price Range <span class="price-range">Value: <span id="demo"></span></span></label>
				      <div class="slidecontainer">
				        <input type="range" min="1000" max="100000" value="5000" class="slider" id="myRange" />
				      </div>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Size</label>
				      <select class="form-select" aria-label="Default select example">
				        <option value="">Select Area</option>
				        <option value="1">1000 sq. ft</option>
				        <option value="2">2000 sq. ft</option>
				        <option value="3">5000 sq. ft</option>
				      </select>
				    </div>
				    <div class="mb-5">
				      <label  class="form-label">Year Built</label>
				      <select class="form-select" aria-label="Default select example">
				        <option value="">Select Year</option>
				        <option selected>2017</option>
				        <option value="1">2018</option>
				        <option value="2">2019</option>
				        <option value="3">2020</option>
				      </select>
				    </div>
					<button type="submit" class="btn btn-secondary btn-block">Submit</button>
				    </form>
                    </ModalBody>
                   
                        <div>
                        
                        </div>
                    
			</Modal> */}
      	</div>
		)
	}
}
