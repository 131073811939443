import React, { Component } from 'react'

import logoWhite from '../../assets/images/logo-white.webp'
import axios from "axios";
import { APIURL } from '../../components/constants/common';
import Navbar from '../Front/TopNavbar'
import Footer from '../Front/Footer'
import LeftFilter from './LeftFilter'
import TopFilter from './TopFilter'
import queryString from 'query-string'
import List from './List'

export default class Index extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true,
			propertyList:[],
			activePage: 1,
		    limit: 0,
		    totalItemsCount: 0,
			purpose:"",
			size:"",
			min_size:0,
			max_size:50000,
			builtYears:[], 
			year_built:"", 
			denomination:"",
			search:"",
			countryId:"",
			stateId:"",
			category_id:"",
			sub_category_id:"",
			min_price:0,
			max_price:50000000000,
			sortby_filter:"A-Z",
			sub_category_list:[],
            token: JSON.parse(localStorage.getItem("token")),
            norecord:false,
            Loader:false,
            mobileView: false
		}
	}

	getPropertyList(scroll){
        this.setState({
            Loader:true
        })
        const formData = new FormData();

		//formData.append('purpose', this.state.purpose);For Sale
        formData.append('purpose', "For Sale");
		formData.append('size', this.state.size);
		formData.append('min_size', this.state.min_size);
		formData.append('max_size', this.state.max_size);
		formData.append('year_built', this.state.year_built);
		formData.append('denomination', this.state.denomination);
		formData.append('search', this.state.search);
        formData.append('state', this.state.stateId);
		formData.append('category_id', this.state.category_id);
		formData.append('sub_category_id', this.state.sub_category_id);
		formData.append('min_price', this.state.min_price);
		formData.append('max_price', this.state.max_price);
		formData.append('sortby_filter', this.state.sortby_filter);
        formData.append('page', this.state.activePage);
        formData.append('limit', this.state.limit);
        
        var token = this.state.token
        var app_url = APIURL+"get-search-property"
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (scroll) {
                    window.scrollTo(0,0)
                }
                this.setState({
                    norecord:true,
                    propertyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    Loader: false
                })
            })
            .catch((error) => {
                this.setState({
                    // errMsg: error.response.data.errors,
                    norecord:true,
                    Loader: false
                })
            });
       
	}

	componentDidMount(){
        window.scrollTo(0,0)
	    this.getQueryString();
	    this.getYear();
	}

	getYear(){
		let minOffset = 0, maxOffset = 1990;
	    let thisYear = (new Date()).getFullYear();
	    let allYears = [];
	    for(let x = maxOffset; x <= thisYear; x++) {
	        allYears.push(x)
	    }

	    this.setState({
            builtYears:allYears
        })
	}
   
   getQueryString(){
        let type="";  
        let category="";  
        let country="";  
        let state="";  
        let search=""; 
        const value = queryString.parse(this.props.location.search);
        const typeRs=value.type;
        const searchRs=value.search;
        const countryRs=value.country;
        const stateRs=value.state;
        const categoryRs=value.category;
        if(typeRs) {
            type=typeRs;
        }
        if(searchRs) {
            search=searchRs;
        } if(countryRs)
        {
            country=countryRs;
            this.setState({
                countryId:country
            },()=>{this.handleCountryState(country)})
        }
        if(stateRs) {
            state=stateRs;
        }
        if(categoryRs) { 
            category=categoryRs;
            this.setState({
                category_id:categoryRs
            },()=>{this.getSubCategory()})
        }

        this.setState({
            purpose: type,
            search:search,
            stateId:state
        },()=>{this.getPropertyList(1)})  	 
    }
	

	handleSize = (e) =>{
        this.setState({
            size: e
        },()=>{this.getPropertyList(1)})
    }

    handleYearBuilt = (e) =>{
        this.setState({
            year_built: e
        },()=>{this.getPropertyList(1)})
    }

    handlePurpose = (e) =>{
        this.setState({
            purpose: e
        },()=>{this.getPropertyList(1)})
    }

    handleSortby = (e) =>{
        this.setState({
            sortby_filter: e
        },()=>{this.getPropertyList(1)})
    }
    
    handlesubCategory = (e) =>{
        this.setState({
            sub_category_id: e
        },()=>{this.getPropertyList(1)})  
    }

    minMaxRangeSelector(min,max){
        this.setState({
            min_price: min,
            max_price: max
        },()=>{this.getPropertyList(1)})
    }

    minMaxSizeRangeSelector(min,max){
        this.setState({
            min_size: min,
            max_size: max
        },()=>{this.getPropertyList(1)})
    }

    getSubCategory(){
         const formData = new FormData();
            formData.append('category_id', this.state.category_id);
            var token = this.state.token
            axios
                .post(APIURL + "get-sub-category-list", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        sub_category_list: response.data.data,
                       
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
    }

    handleCategory = (e) =>{
        this.setState({
            category_id: e,
            sub_category_id:""
        },()=>{this.getSubCategory();this.getPropertyList(1) })
    }

    handleState(e) {
        this.setState({
            stateId: e
        },()=>{
            this.getPropertyList(1)
        })
    }

    handleSearch(e) {
        this.setState({
            search: e
        },()=>{this.getPropertyList(1)})
    }

     handleDenomination(e) {
        this.setState({
            denomination: e
        },()=>{this.getPropertyList(1)})
    }
    
    handleCountry(e) {
        this.setState({
            countryId: e
        }, () => {
            this.handleCountryState(e)
        })
    };

    handlePropsPageChange(activePage) {
        this.setState({
            activePage: activePage
        },()=>{this.getPropertyList(1)})
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

	render() {
		return (
		<div>	
		
				{/* Navbar cmp */}
				<Navbar activePage="search" />
				{/* header cmp */}

				
	            <div class="content-area">
		            <div class="breadcrumb-area gradient1">
				        <div class="container">
				          <h1>Property List</h1>
				          <div><small>Select Your Property With filters</small></div>
				        </div>
				    </div>
					<section id="explore_places" class="burger">
						<div class="container">
							<div class="row">
								<div class="col-lg-4">
								    <LeftFilter 
								    purpose={this.state.purpose}
								    handlePurpose={this.handlePurpose.bind(this)}
								    category_id={this.state.category_id}
								    handleCategory={this.handleCategory.bind(this)}
								    sub_category_list={this.state.sub_category_list}
								    sub_category_id={this.state.sub_category_id}
								    handlesubCategory={this.handlesubCategory.bind(this)}
                                    size={this.state.size}
								    handleSize={this.handleSize.bind(this)}
								    year_built={this.state.year_built}
								    handleYearBuilt={this.handleYearBuilt.bind(this)}
								    min_price={this.state.min_price}
			                        max_price={this.state.max_price}
			                        minMaxRangeSelector={this.minMaxRangeSelector.bind(this)}
			                        min_size={this.state.min_size}
			                        max_size={this.state.max_size}
			                        minMaxSizeRangeSelector={this.minMaxSizeRangeSelector.bind(this)}
			                        countryId={this.state.countryId}
		                        	stateId={this.state.stateId}
                                    handleState={this.handleState.bind(this)}
			                        handleCountry={this.handleCountry.bind(this)}
			                        search={this.state.search}
                                    handleSearch={this.handleSearch.bind(this)}
                                    builtYears={this.state.builtYears}
                                    handleDenomination={this.handleDenomination.bind(this)}
                                    denomination={this.state.denomination}
                                    mobileView = {this.state.mobileView}
                                    toggleView={this.toggleView.bind(this)}
                                    getPropertyList={this.getPropertyList.bind(this)}                                 
								    />
								</div>
								<div class="col-lg-8">
								    <div class="property-area property-list-area">
                                        <TopFilter 
                                            sortby_filter={this.state.sortby_filter}
                                            handleSortby={this.handleSortby.bind(this)}
                                            propertyListLength={this.state.propertyList.length}
                                            totalproperty={this.state.totalItemsCount}
                                            toggleView={this.toggleView.bind(this)}
                                        />
                                        <List propertyList={this.state.propertyList}  
                                            activePage={this.state.activePage}
                                            norecord={this.state.norecord}
                                            totalItemsCount={this.state.totalItemsCount}
                                            Loader={this.state.Loader}
                                            limit={this.state.limit} 
                                            handlePropsPageChange={this.handlePropsPageChange.bind(this)}
                                            getPropertyList={this.getPropertyList.bind(this)}
                                        />
									</div>
								</div>
							</div>
						</div>
					</section>
				
                </div>
				
				{/* footer cmp */}
				<Footer />

		</div>	
		)
	}
}
