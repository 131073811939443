import React, { Component } from 'react'
import logo from '../assets/images/logo-white.webp'
import { Link } from 'react-router-dom'
import axios from "axios";
import { Spinner ,    Button } from 'reactstrap';
import { Redirect } from 'react-router-dom'
import { APIURL } from '../components/constants/common';
import Navbar from './Front/TopNavbar'
import Footer from './Front/Footer'
import facebook from '../assets/images/facebook.svg'
import twitter from '../assets/images/twitter.svg'

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {
                email: "",
                password: "",
                password_confirmation: ""
            },
            errMsg: "",
            showPassword: false,
            Cofirm_showPassword: false,
        }
    }

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
        console.log(userInfo)
    };



    onSubmitHandler = (e) => {
        e.preventDefault()
        const params = this.props.match.params;
        this.setState({ Loader: true });
        axios
            .post(APIURL + "password/reset/update", {
                token: params.token,
                email: this.state.userInfo.email,
                password: this.state.userInfo.password,
                password_confirmation: this.state.userInfo.password_confirmation,
            })
            .then((response) => {
                this.setState({ Loader: false });
                this.setState({
                    scsMsg: response.data.message,
                });
                setTimeout(() => this.setState({ scsMsg: "" }), 3000);
                setTimeout(() => this.setState({ redirect: true }), 4000);
            })
            .catch((error) => {
                console.log(error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
                setTimeout(() => this.setState({ errMsg: "" }), 4000);
            });
    };

    showPassword() {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
    Cofirm_showPassword() {
        this.setState({
            Cofirm_showPassword: !this.state.Cofirm_showPassword
        })
    }

    render() {
        console.log(this.props)
        if (this.state.redirect) {
            return <Redirect to="/signin" />;
        }
        return (

            <div class="resido-front">
                {/* Navbar cmp */}
                <Navbar />
                {/* header cmp */}
                <div class="content-area">
                   <div class="authentication">
                        <div class="container">
                          <div class="row g-0">
                            <div class="col-sm-12">
                              <div class="authentication__content">
                                <div class="row g-0 align-self-center">
                                    <div class="col-md-6">
                                        <div class="mx_h-600">
                                            <div class="px-5">
                                                <h1>Reset Password</h1>
                                                  {this.state.scsMsg ? <div class="alert alert-success" role="alert">
                                                        {this.state.scsMsg}
                                                    </div> : ""}
                                                 <form onSubmit={(e) => this.onSubmitHandler(e)}>
                                                <div className="form-group mb-2">
                                                    <label>Email <strong className="text-danger" >*</strong></label>
                                                    <div className="input-with-icon">
                                                        <input
                                                            className="form-control"
                                                            required=""
                                                            type="email"
                                                            name="email"
                                                            placeholder="email"
                                                            value={this.state.email}
                                                            onChange={this.onChangehandler}
                                                        />
                                                        <span className="text-danger">{this.state.errMsg.email}</span>
                                                        <i className="ti-user"></i>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label>New Password <strong className="text-danger" >*</strong></label>
                                                    <div>
                                                        <div className="icon-input">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                name="password"
                                                                placeholder="Password"
                                                                value={this.state.password}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i className="ti-unlock"></i>
                                                            {this.state.showPassword ?
                                                                <span class="input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye-slash"></i> </span>
                                                                :
                                                                <span class="input-visiblity"> <i onClick={() => this.showPassword()} className="fas fa-eye"></i> </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <span className="text-danger">{this.state.errMsg.password}</span>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label>Confirm Password <strong className="text-danger" >*</strong></label>
                                                    <div>
                                                        <div className="icon-input">
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type={this.state.Cofirm_showPassword ? "text" : "password"}
                                                                name="password_confirmation"
                                                                placeholder="Confirm Password"
                                                                value={this.state.password_confirmation}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <i className="ti-unlock"></i>
                                                            {this.state.Cofirm_showPassword ?
                                                                <span class="input-visiblity"> <i onClick={() => this.Cofirm_showPassword()} className="fas fa-eye-slash"></i> </span>
                                                                :
                                                                <span class="input-visiblity"> <i onClick={() => this.Cofirm_showPassword()} className="fas fa-eye"></i> </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <span className="text-danger">{this.state.errMsg.password_confirmation}</span>
                                                </div>


                                                <div className="form-group">
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-md full-width btn-theme-light-2 rounded mt-4"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 order-md-first">
                                        <div class="px-5 authentication__withsocial gradient1">
                                            <div class="content">
                                                <a href="#"><img loading="lazy" src={logo} class="brand-logo" alt="xenon Logo" /></a>
                                                <h2>Glad to See You!</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}
