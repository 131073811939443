import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../../components/constants/common';
import logoColor1 from '../../assets/images/logo-white.webp'
import logo_color from '../../assets/images/logo-color.webp'
import AML from '../../assets/images/AML.png'
import AXA from '../../assets/images/AXA-insurance-Logo.png'
import PRS from '../../assets/images/PRS_Logo_high.png'
import {ReactComponent as Facebook} from '../../assets/images/facebook.svg'
import {ReactComponent as Linkedin} from '../../assets/images/linkedin.svg'
import {ReactComponent as Instagram} from '../../assets/images/instagram.svg'
import {ReactComponent as Twitter} from '../../assets/images/twitter.svg'
import { Link } from 'react-router-dom'

     
export default class Footer extends Component {
	constructor(props){
		super(props);
		this.state = {
			token: JSON.parse(localStorage.getItem("token")),
			user: JSON.parse(localStorage.getItem("userData")),
			isLoggedin: localStorage.getItem("isLoggedIn"),
			category_list:[]
		}
	}
	
	componentDidMount(){
        this.getCategory()
	}

	getCategory(){
		const formData = new FormData();
		var token = this.state.token
		axios
			.post(APIURL + "get-category-list", formData, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			.then((response) => {
				this.setState({
					category_list: response.data.data,  
				})
			})
			.catch((error) => {
				this.setState({
					errMsg: error.response.data.errors,
					Loader: false
				})
			});
   	}

    render() {
        return (
            <div>
                <footer>
				<div class="container" >
					<div class="row mt-5 mb-5"> 
						<div class="col-lg-3 col-md-4">
							<div class="footer-logo">
								<a href="#"><img loading="lazy" style={{height:"100px"}} src={logoColor1} alt="xenon Logo" /></a>
							</div>
							<div class="mt-4">
								<ul class="footer_link">
									<li><strong>Address:</strong> 04A-04B, Ground Floor, Spaze i-Tech Park, Sohna Road, Gurugram, Haryana 122002</li>
									<li><a href="tel:+447879146191"><strong>Phone:</strong> +91 91117 55523</a></li>
									<li><a href="mailto:xenon@imenso.in"><strong>Email:</strong> xenon@imenso.in</a></li> 
								</ul>
								
							</div>
						</div>  

						<div class="col-lg-9 col-md-8">
							<div class="row">
								<div class="col-lg-3 col-sm-6">
									<h4>Navigations</h4>
									<div class="mt-4">
										<ul class="footer_link ">
											<li><Link to="/page/about-us">About Us</Link></li>
											{/* <li><a href="#">FAQs Page</a></li> */}
											<li><Link to="/contact-us">Contact</Link></li>
											<li><Link to="/page/terms-condition">Terms of Use</Link></li>
											<li><Link to="/page/privacy-policy">Privacy Policy</Link></li>
											<li><Link to="/page/cookies-policy">Cookies Policy</Link></li>
										</ul>
									</div>
								</div>

								<div class="col-lg-3 col-sm-6">
									<h4>The Highlights</h4>
									<div class="mt-4">
										<ul class="footer_link">
											{ this.state.category_list.length > 0 && this.state.category_list.map((item, index) => (
												<li><a href={"/search?category="+item.id}>{item.category_name}</a></li>
												// to={}
											))}
										</ul>
									</div>
								</div>

								<div class="col-lg-3 col-sm-6">
									<h4>My Account</h4>
									<div class="social mt-4">
										<ul class="footer_link">
											{ this.state.user!=null && this.state.user.user_type=="Buyer" &&
												<li><Link to="/buyer">My Profile</Link></li>
											}
											{ this.state.user!=null && this.state.user.user_type=="Seller" &&
												<li><Link to="/seller">My Profile</Link></li>
											}
											{ this.state.user!=null && this.state.user.user_type=="Agent" &&
												<li><Link to="/agent">My Profile</Link></li>
											}
											{ this.state.user!=null && this.state.user.user_type=="Admin" &&
												<li><Link to="/admin">My Profile</Link></li>
											}
											{ this.state.user === null && this.state.isLoggedin === false &&
												<li><Link to="/signin">My Profile</Link></li>
											}
											<li><Link to="/search">Find Property</Link></li>
											{ this.state.user!=null && this.state.user.user_type=="Buyer" &&
												<li><Link to="/buyer/wishlist">Favorites</Link></li>
											}
											{ this.state.user === null && this.state.isLoggedin === false &&
												<li><Link to="/signin">Favorites</Link></li>
											}
										</ul>
									</div>
								</div>

								<div class="col-lg-3 col-sm-6">
									<h4>Connect</h4>
									<div class="social mt-4">
										<ul>						        
											<li><a target="_blank" href="https://www.facebook.com/">
												<Facebook class="img-svg" alt="user" /> <span>Facebook</span> 
											</a></li>
											<li><a target="_blank" href="https://twitter.com/">
												<Twitter class="img-svg" alt="user" /> <span>Twitter</span> 
											</a></li>
											<li><a target="_blank" href="https://www.instagram.com//">
												<Instagram class="img-svg" alt="user" /> <span>Instagram</span>
											</a></li>
										</ul>
									</div>
								</div>	
							</div>	
						</div>	
					</div>
				</div>
				{/* <div class="copyright">		
					<div class="container">
						<div class="row">
							<div class="col-sm-6">© 2021 Xenon Realestate. All Rights Reserved</div>
							<div class="col-sm-6 text-right"><a href="#">Privacy Policy</a> | <a href="#">Sales Conditions</a></div>
						</div>
					</div>
				</div> */}
			</footer>
            </div>
        )
    }
}
